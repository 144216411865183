<template>
	<div id="menu-lateral-admin">
		<div class="row">
			<div class="topo">
				<div class="perfil">
					<div class="logo">
                        <LogoFinerFoodsBranca/>
					</div>
					<div class="nome">
						Olá,
						<span>
                            {{dados_usuario.nome}}
                        </span>
					</div>
				</div>
			</div>
			<ul>
				<li>
					<router-link :to="'/admin/perfil'" v-if="$store.getters.isLoggedIn">
						<v-icon>fas fa-user-alt</v-icon>
						<span class="texto">
							Meu perfil
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/admin/usuarios'" v-if="$store.getters.isLoggedIn && $store.getters.grupo == 1">
						<v-icon>fas fa-users</v-icon>
						<span class="texto">
							Usuários
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/admin/empresas'" v-if="$store.getters.isLoggedIn && $store.getters.grupo == 1">
						<v-icon>fas fa-building</v-icon>
						<span class="texto">
							Empresas
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/admin/empresas-servicos'" v-if="$store.getters.isLoggedIn && $store.getters.grupo == 1">
						<v-icon>fas fa-clipboard-list</v-icon>
						<span class="texto">
							Empresas / Serviços
						</span>
					</router-link>
				</li>
				<li v-for="(item, index) in $store.getters.menus_usuario" :key="index">
					<router-link :to="item.external_menu ? item.external_menu : item.link" v-if="$store.getters.isLoggedIn && item.external_menu == null">
						<v-icon>{{item.icone}}</v-icon>
						<span class="texto">
							{{item.nome}}
						</span>
					</router-link>
					<a :href="item.external_menu" v-if="$store.getters.isLoggedIn && item.external_menu != null">
						<v-icon>{{item.icone}}</v-icon>
						<span class="texto">
							{{item.nome}}
						</span>
					</a>
				</li>
				<li v-if="$store.getters.screen_size.width <= 768">
					<router-link @click.native="closeMenu" to="logout">
						<span class="icone">
							<i class="fas fa-sign-out-alt"></i>
						</span>
						<span class="texto">
							Sair
						</span>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import store from '@/store'
    import LogoFinerFoodsBranca from "@/components/LogoFinerFoodsBranca.vue"
	
	export default {
		name: 'MenuLateralAdmin',
		components: {
            LogoFinerFoodsBranca
		},
		data: () => ({
			dados_usuario: {},
			estadoMenu: '',
		}),
		props:{
			estadoMenuLateralAdmin:[Function]
		},
		methods: {
			closeMenu(){
				if (store.getters.screen_size.width < 768) {
					store.dispatch('toggleMenu')
				}
			},
			init(){
				}
		},
		mounted() {
			this.init()
			if (store.getters.isLoggedIn) {
				this.dados_usuario = store.getters.user
            }
		},
		watch:{
			estadoMenu:function(estadoMenu){
                this.$emit('estadoMenuLateralAdmin',estadoMenu)
				this.estadoMenu = ''
            }
        },
	}
</script>

<style lang="scss">
	#menu-lateral-admin{
		text-align: center;
		padding: 0 12px;
		height: 100vh;
		position: relative;
		z-index: 1;
		background-color: #242424;
		font-family: 'Poppins';
		font-weight: 400;
		overflow-y: auto;
		.nome{
			font-weight: 300;
			color: #fff;
			span{
				font-weight: 600;
				margin-left: 3px;
			}
		}
	}
</style>