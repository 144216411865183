<template>
	<div id="dashboard">
		<div class="container-fluid main-container">
			<div class="row main-row">
				<div class="left" v-bind:class="{ open: $store.getters.open_menu }">
					<div @click="minimize" class="background"></div>
					<MenuLateralAdmin/>
				</div>
				<div class="right" v-bind:class="{ open: $store.getters.open_menu }">
					<div class="row right-row">
						<div class="top">
							<NavBar/>
						</div>
						<div class="page-content">
							<router-view/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import MenuLateralAdmin from '@/components/MenuLateralAdmin.vue'
	import NavBar from '@/components/NavBar.vue'
	import store from '@/store'
	
	export default {
		name: 'Dashboard',
		components: {
			MenuLateralAdmin,
			NavBar
		},
		methods: {
			minimize() {
				store.dispatch('toggleMenu')
			},
		}
	}
</script>

<style lang="scss">
	#dashboard{
		height: 100vh;
		display: flex;
		flex: 1;
		@media (max-width: 767px) {
			max-width: 100vw;
		}
		.main-container{
			height: 100vh;
			display: flex;
			flex: 1;
			padding: 0 12px;
			.main-row{
				height: 100vh;
				flex: 1;
				position: relative;
				@media (max-width: 767px) {
					max-width: 100vw;
				}
				.left{
					position: fixed;
					top: 0;
					transition: all ease .3s;
					width: 8vw;
					z-index: 10;
					-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25); 
					box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
					max-height: 100vh;
					.background{
						display: none;
					}
					#menu-lateral-admin{
						.topo{
							padding: 20px 5px;
							.perfil{
								.logo{
									width: 100%;
									transition: all ease .3s;
								}
								.nome{
									display: none;
								}
							}
						}
						ul{
							padding: 0;
							list-style: none;
							margin: 0;
							width: 100%;
							border-top: 1px solid #fff;
							li{
								border-bottom: 1px solid #fff;
								a{
									position: relative;
									padding: 15px;
									display: flex;
									justify-content: center;
									color: #fff;
									font-weight: 600;
									align-items: center;
									text-transform: uppercase;
									transition: all ease .3s;
									svg{
										display: flex;
										transition: all ease .3s;
										path{
											fill: #fff;
										}
									}
									span{
										position: absolute;
										word-break: break-all;
										font-size: 12px;
										opacity: 0;
										transition: all ease .3s;
									}
									&.active,
									&:hover{
										svg{
											opacity: 0;
										}
										background-color: $primarycolor;
										span{
											opacity: 1;
										}
									}
								}
							}
						}
					}
					@media (max-width: 768px) {
						.background{
							display: block;
							background-color: #33333333;
							content: "";
							backdrop-filter: blur(7px);
							position: absolute;
							left: 100%;
							top: 0;
							visibility: hidden;
							opacity: 0;
							width: 100vw;
							height: 100vh;
						}
					}
					.wrapper-btn-minimize{
						display: flex;
						justify-content: flex-end;
						.btn-minimize{
							border: none;
							background-color: transparent;
							align-self: flex-end;
							transform: rotate(-180deg);
							transition: all ease .3s;
							height: 52px;
						}
					}
					&.open{
						width: 20vw;
						.btn-minimize{
							transform: rotate(0deg);
						}
						#menu-lateral-admin{
							.topo{
								display: flex;
								align-items: center;
								flex: 1;
								padding: 30px 15px;
								.btn-voltar{
									display: flex;
									flex-wrap: wrap;
									justify-content: flex-start;
									width: max-content;
									padding: 10px 15px;
									svg{
										path{
											fill: #FE8204;
										}
									}
								}
								.perfil{
									display: flex;
									flex-wrap: wrap;
									align-items: center;
									justify-content: space-evenly;
									width: 100%;
									.logo{
										width: 40%;
									}
									.nome{
										display: flex;
									}
								}
							}
							ul{
								li{
									a{
										justify-content: flex-start !important;
										svg{
											display: none;
										}
										span{
											display: flex;
											position: relative;
											font-size: 18px;
											opacity: 1;
										}
									}
								}
							}
						}
						@media (max-width: 768px) {
							.background{
								visibility: visible;
								opacity: 1;
							}
						}
					}
					@media (max-width: 767px) {
						position: fixed;
						right: 100%;
    					max-width: 100vw;
						z-index: 100;
						box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
						&.open{
							width: 300px;
							z-index: 10;
							transform: translateX(100%);
							background-color: #fff;
						}
					}
				}
				.right{
					display: flex;
					flex: 1;
					padding: 0 12px;
					width: 92vw;
					padding-left: 8vw;
					transition: all ease .3s;
					@media (max-width: 767px) {
    					max-width: 100vw;
					}
					.right-row{
						flex: 1;
						flex-direction: column;
						.top{
							position: sticky;
							top: 0;
							z-index: 5;
							-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25); 
							box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
						}
						.page-content{
							margin-left: 12px;
							width: 92vw;
							background-color: #404040;
							flex: 1;
							transition: all ease .3s;
							@media (max-width: 767px) {
								max-width: 100vw;
							}
						}
					}
					&.open{
						width: 80vw;
						padding-left: 20vw;
						.right-row{
							.top{
								padding-left: 0;
							}
							.page-content{
								width: 80vw;
							}
						}
					}
				}
			}
		}
	}
</style>