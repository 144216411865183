<template>
	<div id="nav-bar">
		<div class="nav-bar-row">
			<div class="btn-mobile">
				<button @click="minimize">
					<span></span>
				</button>
			</div>
			<div class="busca"></div>
			<div class="menu">
				<ul>
					<li class="dia">
						{{dia_semana}}, {{dia}} 
					</li>
					<li class="horario">
						<v-icon>far fa-clock</v-icon> {{hora}}
					</li>
					<li v-if="$store.getters.screen_size.width >= 768" class="usuario">
						<button @click="activeMenu">
							<figure>
								{{iniciais}}
							</figure>
							<i class="fas fa-chevron-down"></i>
						</button>
						<transition name="fade">
							<div v-if="menu" class="sub-menu">
								<ul>
									<li>
										<router-link to="/logout">
											<v-icon>fas fa-door-open</v-icon>
											Sair
										</router-link>
									</li>
								</ul>
							</div>
						</transition>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import store from '@/store'
	import moment from 'moment'

	export default {
		name: 'NavBar',
		data: () => ({
			dia_semana: "",
			dia: "",
			hora: "",
			menu: false,
			iniciais: '',
			dados_usuario: {},
		}),
		methods: {
			async init(){
				if(this.$store.getters.user){
					var name = this.$store.getters.user.nome
					var initials = name.match(/\b\w/g) || []
					this.iniciais = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
				}
			},
			minimize() {
				store.dispatch('toggleMenu')
			},
			getNow() {
				this.dia_semana = moment().format('dddd')
				this.dia = moment().format('LL')
				this.hora = moment().format('LTS')
			},
			activeMenu() {
				this.menu = !this.menu
			},
		},
		async mounted(){
            this.init()
			setInterval(this.getNow, 1000)
		},
	}
</script>

<style lang="scss">
	#nav-bar{
		color: #242424;
		background-color: $primarycolor;
		font-family: 'Poppins';
		.nav-bar-row{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.btn-mobile{
				display: flex;
				padding: 10px 20px;
				button{
					color: #242424;
					font-size: 20px;
					display: flex;
					height: 100%;
					height: 30px;
					width: 30px;
					span{
						display: block;
						top: 50%;
						width: 30px;
						height: 3px;
						transform: translateY(-50%);
						background-color: #242424;
						border-radius: 4px;
						position: relative;
						transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s; 
						&:before,
						&:after{
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							background-color: #242424;
							border-radius: 4px;
							position: absolute;
							transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
						}
						&:before {
							top: 7px;
							transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s; 
						}
						&:after {
							bottom: 7px;
						}
					}
				}
				&.open{
					button{
						span{
							transform: translate3d(0, 0px, 0) rotate(45deg);
							background-color: #242424;
							&:before{
								transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
								opacity: 0;
								background-color: #242424;
							}
							&:after{
								transform: translate3d(0, 7px, 0) rotate(-90deg);
								background-color: #242424;
							}
						}
					}
				}
			}
			.menu{
				display: flex;
				ul{
					padding: 0;
					display: flex;
					align-items: center;
					margin-bottom: 0;
					li{
						display: inline-flex;
						padding: 10px 20px;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						align-items: center;
						color: #242424;
						button{
							background-color: transparent;
							border: none;
							color: #242424;
							display: flex;
						}
						&.horario{
							width: 150px;
							svg{
								margin-left: 15px;
								margin-right: 5px;
								font-size: 20px;
								path{
									fill: #242424;
								}
							}
						}
						&.usuario{
							justify-content: center;
							align-items: center;
							position: relative;
							button{
								justify-content: center;
								align-items: center;
								figure{
									margin-bottom: 0;
									margin-right: 15px;
									height: 30px;
									width: 30px;
									background-color: #fff;
									border-radius: 100%;
									font-size: 16px;
									display: flex;
									align-items: center;
									justify-content: center;
									color: #242424;
									border: 2px solid #242424;
									text-transform: uppercase;
								}
							}
							.sub-menu{
								position: absolute;
								z-index: 1;
								left: 40%;
								top: 100%;
								padding-top: 15px;
								transform: translateX(-50%);
								&:after,
								&:before{
									content: '';
									box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
									position: absolute;
								}
								&:before{
									top: 15px;
									right: 0;
									bottom: 0;
									left: 0;
									border-radius: 10px;
									// border: 1px solid red;
								}
								&:after{
									transform: rotate(45deg) translateX(-50%);
									width: 20px;
									height: 20px;
									top: 13px;
									box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
									left: 50%;
									border-radius: 5px 0;
								}
								ul{
									background-color: #fff;
									z-index: 2;
									border-radius: 10px;
									position: relative;
									&:before{
										content: '';
										background-color:  #fff;
										position: absolute;
										transform: rotate(45deg) translateX(-50%);
										width: 20px;
										height: 20px;
										top: -2px;
										left: 50%;
										border-radius: 5px 0;
									}
									a{
										display: flex;
										flex-wrap: nowrap;
										align-items: center;
										color: $primarycolor;
										font-weight: 500;
										font-size: 16px;
										svg{
											margin-right: 10px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>